
import { Vue, Component } from "vue-property-decorator";
import AuthService from "@/services/auth_service";
import { GenericError } from "@/services/error_service";

@Component
export default class RequestEmailForm extends Vue {
  protected email = "";
  protected authService = new AuthService();
  protected resetPass = false;
  protected sendEmail = false;
  protected error = "";
  protected message = "";
  protected verificationMsg = "Email verification request received. Please check your email."

  created() {
    if (this.$route.query.request === "password") {
      this.resetPass = true;
    } else if (this.$route.query.request === "email") {
      this.sendEmail = true;
    } else {
      this.$router.push("/");
    }
  }

  public async handleSubmit(): Promise<void> {
    try {
      if (this.resetPass) {
        await this.authService.requestNewPassword(this.email);
        this.message = "Password request received. Please check your email.";
      } else {
        await this.authService.resendEmailVerification(this.email);
        this.message = this.verificationMsg;
      }
      this.error = "";
    } catch (err) {
      if (err instanceof GenericError) {
        GenericError.popup(err.message);
      } else {
        this.error = err.message;
      }
    }
  }
}
